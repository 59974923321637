import '../../assets/css/notifications.css';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Actions from '../data/Actions';
import Session from '../data/Session';
import State from '../data/State';
import { pushTypes } from '../types/enums';

type option = {
  key: pushTypes,
  title: string
}
const options: option[] = [
  {
    key: pushTypes.ALL,
    title: 'Все'
  },
  {
    key: pushTypes.ID,
    title: 'По ID пользователя'
  },
  {
    key: pushTypes.PHONE,
    title: 'По номеру телефона'
  },
  {
    key: pushTypes.SUM,
    title: 'По остатку основного долга'
  },
  {
    key: pushTypes.DATE,
    title: 'По дате договора'
  },
  {
    key: pushTypes.PENALTY,
    title: 'По сумме пени'
  },
];

const all = () => {
  return (
    <div className='input-block'>
      <input id='param-1' type='text' style={{ background: '#d9d9d9' }} disabled />
    </div>
  );
}
const id = () => {
  return (
    <div className='input-block'>
      <input id='param-1' type='text' onChange={e => State.setPushParam1(e.target.value)} placeholder='введите id' />
    </div>
  );
}
const phone = () => {
  return (
    <div className='input-block'>
      <input id='param-1' type='text' onChange={e => State.setPushParam1(e.target.value)} placeholder='введите номер' />
    </div>
  );
}
const sum = () => {
  return (
    <div className='input-params'>
      <input id='param-1' type='text' onChange={e => State.setPushParam1(e.target.value)} placeholder='с' />
      <input id='param-2' type='text' onChange={e => State.setPushParam2(e.target.value)} placeholder='до' />
    </div>
  );
}
const date = () => {
  const date = new Date();
  const day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
  const month = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
  const placeholder = day + '.' + month + '.' + date.getFullYear();
  return (
    <div className='input-params'>
      <input id='param-1' type='text' onChange={e => State.setPushParam1(e.target.value)} placeholder={ placeholder } />
      <input id='param-2' type='text' onChange={e => State.setPushParam2(e.target.value)} placeholder={ placeholder } />
    </div>
  );
}
const penalty = () => {
  return (
    <div className='input-params'>
      <input id='param-1' type='text' onChange={e => State.setPushParam1(e.target.value)} placeholder='с' />
      <input id='param-2' type='text' onChange={e => State.setPushParam2(e.target.value)} placeholder='до' />
    </div>
  );
}

export default observer(() => {
  useEffect(() => {
    (async () => {
      Actions.getPushUsers();
    })();
  }, []);

  State.getPushUsersUpdate();
  const users: IpushUser[] = Session.getPushUsers();

  const option = options.map(tag => {
    return <option key={ tag.key } value={ tag.key }>{ tag.title }</option>
  });

  const input =
    State.getPushType() === pushTypes.ID ? id() :
    State.getPushType() === pushTypes.PHONE ? phone() :
    State.getPushType() === pushTypes.SUM ? sum() :
    State.getPushType() === pushTypes.DATE ? date() :
    State.getPushType() === pushTypes.PENALTY ? penalty() :
    all();
  
  return (
    <>
      <div className='notifications-header sides-padding'>Уведомления</div>
      <div className='notification-block'>
        <div className='chapter'>
        <div className='input'>
          <div className='label'>Получатели: <b style={{color: 'red'}}>{ users.length.toString() }</b></div>
          <div className='input-block'>
            <select className='input-select' onChange={
              (e: React.ChangeEvent<HTMLSelectElement>) => {
                const type = e.currentTarget.value as pushTypes;
                State.setPushParam1('');
                State.setPushParam2('');
                const param1 = document.querySelector('#param-1') as HTMLInputElement;
                const param2 = document.querySelector('#param-2') as HTMLInputElement;
                if (param1) param1.value = '';
                if (param2) param2.value = '';
                State.setPushType(type);
              }
            }>{ option }</select>
          </div>
        </div>
        <div className='input'>
          <div className='label'>Параметры:</div>
          { input }
        </div>
        <div className='input'>
          <div className='label'>Заголовок:</div>
          <div className='input-block'>
            <input type='text' id='title' />
          </div>
        </div>
        <div className='input'>
          <div className='label'>Текст:</div>
          <div className='input-block'>
            <textarea id="text"></textarea>
          </div>
        </div>
        <div className='chapter-button' onClick={() => {
          const title: HTMLInputElement = document.querySelector('#title');
          const text: HTMLInputElement = document.querySelector('#text');

          if (title.value !== '' && text.value !== '') {
            const result = [];
            users.map(user => result.push({ id: user.id, push_token: user.push_token }));
            Actions.sendNotifications(title.value, text.value, result);
            alert('Добавлено в очередь на отправку');
            title.value = '';
            text.value = '';
          } else {
            alert('Мало бук0в :(');
          }
        }}>Отправить</div>
        </div>
      </div>
    </>
  );
});