import { useState } from 'react';
import Actions from '../data/Actions';
import State from '../data/State';

export default () => {
  const [query, setQuery] = useState(false);
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [repeatPass, setRepeatPass] = useState('');
  const [passVisible, setPassVisible] = useState(false);
  const [repeatPassVisible, setRepeatPassVisible] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [passError, setPassError] = useState(false);

  return (
    <div className='chapter-content'>
      <div className='header'>Создать<br />пользователя</div>
      <div className='chapter'>
        <div className='input'>
          <div className='label'>Введите имя</div>
          <div className='input-block'>
            <input
              className={ nameError ? 'input-error' : '' }
              type='text'
              value={ name }
              onChange={(e) => {
                setNameError(false);
                setName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className='input'>
          <div className='label'>Введите логин (только латинсике буквы)</div>
          <div className='input-block'>
            <input
              className={ loginError ? 'input-error' : '' }
              type='text'
              value={ login }
              onChange={(e) => {
                setLoginError(false);
                setLogin(e.target.value);
              }}
            />
          </div>
        </div>
        <div className='input'>
          <div className='label'>Введите пароль</div>
          <div className='input-block'>
            <input
              className={ passError ? 'input-error' : '' }
              type={ passVisible ? 'text' : 'password' }
              value={ pass }
              onChange={(e) => {
                setPassError(false);
                setPass(e.target.value);
              }}
            />
            <div className='accounts-password-visible' onClick={() => setPassVisible(!passVisible)}></div>
          </div>
        </div>
        <div className='input'>
          <div className='label'>Повторите пароль</div>
          <div className='input-block'>
            <input
              className={ passError ? 'input-error' : '' }
              type={ repeatPassVisible ? 'text' : 'password' }
              value={ repeatPass }
              onChange={(e) => {
                setPassError(false);
                setRepeatPass(e.target.value);
              }}
            />
            <div className='accounts-password-visible' onClick={() => setRepeatPassVisible(!repeatPassVisible)}></div>
          </div>
        </div>
        <div className='chapter-button' onClick={async () => {
          const reg = /^[a-zA-Z]+$/i;

          if (name === '') {
            setNameError(true);
          } else if (!reg.test(login)) {
            setLoginError(true);
          } else if (repeatPass === '' || pass === '' || repeatPass !== pass) {
            setPassError(true);
          } else {
            setQuery(true);
            const result = await Actions.addUser(name, login, pass);

            if (result.error) {

              if (result.typeError === 1) {
                alert('Ошибка авторизации!');
              } else if (result.typeError === 2) {
                alert('Пользователь с таким логином уже существует!');
              } else {
                alert('Неизвестная ошибка!');
              }
            } else {
              State.addAdmin(result.user);
              alert('Пользователь добавлен!');
            }
            setName('');
            setLogin('');
            setPass('');
            setRepeatPass('');
            setQuery(false);
          }   
        }}>Создать</div>
      </div>
    </div>
  );
}