import '../../assets/css/accounts.css';
import AccountsNewUser from './AccountsNewUser';
import AccountsChangePassword from './AccountsChangePassword';
import AccountsUsers from './AccountsUsers';

export default () => {
  return (
    <div className='accounts'>
      <div className='accounts-header sides-padding'>Учетные записи</div>
      <div className='accounts-content'>
        <AccountsNewUser />
        <AccountsUsers />
        <AccountsChangePassword />
      </div>
    </div>
  );
}