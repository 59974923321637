import Utils from './Utils';
import { requestStatus } from '../types/enums';

class Requests {

  private _users: IRequestsUser[] = [];
  private _requests: IuserRequest[] = [];
  private _user: string = null;
  private _id: number = null;
  private _comment: string;
  private _status: requestStatus = requestStatus.PROGRES;

  public setUsers(users: IRequestsUser[]): void {
    this._users = users;
  }

  public getUsers(): IRequestsUser[] {
    return this._users;
  }

  public setUserResquests(requests: IuserRequest[]): void {
    this._requests = requests;
  }

  public getUserResquests(): IuserRequest[] {
    return this._requests;
  }

  public setUserID(user: string): void {
    this._user = user;
  }
  
  public getUserID(): string {
    return this._user;
  }
  
  public clearMarks(id: string): void {
    const user = this._users.find(data => data.id === id);
    user.mark = 0;
  }

  public addUser(user: IRequestsUser): void {
    if (user.id === Utils.getAdminID()) return;
    this._users.push({
      id: user.id,
      name: user.name,
      mark: 1,
      phone: user.phone
    });
  }

  public getUnread(): number {
    let num = 0;
    this._users.map(user => num += user.mark);
    return num;
  }

  public getID(): number {
    return this._id;
  }

  public setID(id: number): void {
    this._id = id;
  }

  public getComment(): string {
    return this._comment;
  }

  public setComment(value: string): void {
    this._comment = value;
  }

  public getStatus(): requestStatus {
    return this._status;
  }

  public setStatus(status: requestStatus): void {
    this._status = status;
  }
}

export default new Requests();