import { useState } from 'react';
import Actions from '../data/Actions';
import User from '../data/User';
import Utils from '../data/Utils';

export default () => {
  const [query, setQuery] = useState(false);
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [repeatPass, setRepeatPass] = useState('');
  const [oldPassVisible, setOldPassVisible] = useState(false);
  const [newPassVisible, setNewPassVisible] = useState(false);
  const [repeatPassVisible, setRepeatPassVisible] = useState(false);
  const [oldError, setOldError] = useState(false);
  const [newError, setNewError] = useState(false);

  return (
    <div className='chapter-content'>
      <div className='header'>Сменить пароль<br />к учетной записи</div>
      <div className='chapter'>
        <div className='input'>
          <div className='label'>Введите старый пароль</div>
          <div className='input-block'>
            <input
              className={ oldError ? 'input-error' : '' }
              type={ oldPassVisible ? 'text' : 'password' }
              value={ oldPass }
              onChange={(e) => {
                setOldError(false);
                setOldPass(e.target.value);
              }}
            />
            <div className='accounts-password-visible' onClick={() => setOldPassVisible(!oldPassVisible)}></div>
          </div>
        </div>
        <div className='input'>
          <div className='label'>Введите новый пароль</div>
          <div className='input-block'>
            <input
              className={ newError ? 'input-error' : '' }
              type={ newPassVisible ? 'text' : 'password' }
              value={ newPass }
              onChange={(e) => {
                setNewError(false);
                setNewPass(e.target.value);
              }}
            />
            <div className='accounts-password-visible' onClick={() => setNewPassVisible(!newPassVisible)}></div>
          </div>
        </div>
        <div className='input'>
          <div className='label'>Повторите новый пароль</div>
          <div className='input-block'>
            <input
              className={ newError ? 'input-error' : '' }
              type={ repeatPassVisible ? 'text' : 'password' }
              value={ repeatPass }
              onChange={(e) => {
                setNewError(false);
                setRepeatPass(e.target.value);
              }}
            />
            <div className='accounts-password-visible' onClick={() => setRepeatPassVisible(!repeatPassVisible)}></div>
          </div>
        </div>
        <div className='chapter-button' onClick={async () => {
          if (query) return;

          if (oldPass === '') {
            setOldError(true);
          } else if (repeatPass === '' || newPass === '' || repeatPass !== newPass) {
            setNewError(true);
          } else {
            setQuery(true);
            const result = await Actions.changePassword(oldPass, newPass);
            setOldPass('');
            setNewPass('');
            setRepeatPass('');
            setQuery(false);

            if (result.error) {
              setOldError(true);
            } else {
              User.setHash(result.hash);     
              const expires = Date.now() + 60 * 60 * 24 * 30 * 1000;
              Utils.setCookie('hash', result.hash, { expires: new Date(expires) });
              alert('Пароль изменен!');
            }
          }
        }}>Сменить</div>
      </div>
    </div>
  );
}