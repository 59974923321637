import State from '../data/State';

export default () => {
  const users = State.getAdmins().map(user => {
    return (
      <div key={ user.id } className='accounts-user-block'>
        <div className='accounts-avatar'></div>
        <div className='accounts-name'>{ user.name }</div>
      </div>
    );
  })

  return (
    <div className='chapter-content'>
      <div className='header'>Учетные записи<br />пользователей</div>
      <div className='chapter' style={{ overflow: 'auto' }}>{ users }</div>
    </div>
  );
}