import { observer } from 'mobx-react-lite';
import State from '../data/State';
import Accounts from './Accounts';
import Chat from './Chat';
import Main from './Main';
import Requests from './Requests';
import Notifications from './Notifications';
import { screens } from '../types/enums';

export default observer(() => {
  const screen =
    State.getScreen() === screens.MAIN ? <Main /> :
    State.getScreen() === screens.CHAT ? <Chat /> :
    State.getScreen() === screens.REQUESTS ? <Requests /> :
    State.getScreen() === screens.ACCOUNTS ? <Accounts /> :
    State.getScreen() === screens.NOTIF ? <Notifications /> :
    null;
  return (
    <div className='content'>
      { screen }
    </div>
  );
})