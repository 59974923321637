import { pushTypes } from '../types/enums';
import State from './State';
import Utils from './Utils';

class Session {

  private _pushUsers: IpushUser[] = [];

  public getPushUsers(): IpushUser[] {
    const type = State.getPushType();
    const param1 = State.getPushParam1();
    const param2 = State.getPushParam2();

    if (type === pushTypes.ID) {
      if (this._pushUsers.find(data => data.id === param1)) {
        return [this._pushUsers.find(data => data.id === param1)];
      }
      return [];
    }
    
    if (type === pushTypes.PHONE) {
      if (this._pushUsers.find(data => data.phone === Number(param1))) {
        return [this._pushUsers.find(data => data.phone === Number(param1))];
      }
      return [];
    }

    if (type === pushTypes.SUM) {
      const users = [];
      this._pushUsers.map(user => {
        user.loans.map(loan => {

          const sum = Number(loan.sum.replace(/\s/g, ''));
          if (sum >= Number(param1) && sum <= Number(param2) && Number(param2) > 0 && !users.find(u => u.id === user.id)) {
            users.push(user);
          }
        });
      })
      return users;
    }

    if (type === pushTypes.DATE) {
      const users = [];
      this._pushUsers.map(user => {
        user.loans.map(loan => {
          const p1 = Utils.getUnixtimeDate(param1);
          const p2 = Utils.getUnixtimeDate(param2);
          const date = Number(loan.date);

          if (date >= p1 && date <= p2 && p2 > 0 && !users.find(u => u.id === user.id)) {
            users.push(user);
          }
        });
      })
      return users;
    }

    if (type === pushTypes.PENALTY) {
      const users = [];
      this._pushUsers.map(user => {
        user.loans.map(loan => {

          const penalty = Number(loan.penalty.replace(/\s/g, ''));
          if (penalty >= Number(param1) && penalty <= Number(param2) && Number(param2) > 0 && !users.find(u => u.id === user.id)) {
            users.push(user);
          }
        });
      })
      return users;
    }
    return this._pushUsers;
  }

  public setPushUsers(users: IpushUser[]): void {
    for (let i = 0; i < users.length; i++) {
      for (let j = 0; j < users[i].loans.length; j++) {
        users[i].loans[j].date = Utils.getUnixtimeDate(users[i].loans[j].date.toString());
      }
    }
    this._pushUsers = users;
  }
}

export default new Session();