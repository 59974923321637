import '../../assets/css/menu.css';
import { observer } from 'mobx-react-lite';
import Chat from '../data/Chat';
import Requests from '../data/Requests';
import State from '../data/State';
import User from '../data/User';
import Utils from '../data/Utils';
import { screens } from '../types/enums';

export default observer(() => {
  State.getChatUpdate();
  State.getRequestsUpdate();
  const ul = Utils.getMenu().map(data => {
    const choose = data.key === State.getScreen() ? 'menu-li-active' : null;
    let notif = null;
    const requests = Requests.getUnread();
    const chat = Chat.getUnread();

    if (data.key === screens.REQUESTS && requests > 0) {
      notif = <div className='notif-menu'>{ requests }</div>
    }

    if (data.key === screens.CHAT && chat > 0) {
      notif = <div className='notif-menu'>{ chat }</div>
    }
    return (
      <li 
        key={ data.key }
        onClick={() => {
          Chat.setChat([]);
          State.setChat(null);
          Requests.setID(null);
          Requests.setUserID(null);
          State.setScreen(data.key);
        }}
        className={ choose }
      >
        { notif }
        { data.text }
      </li>
    );
  });

  return (
    <div className='menu'>
      <div className='menu-header sides-padding'>Admin панель</div>
      <div className='menu-user-block sides-padding'>
        <div className='avatar'></div>
        <div className='name'>{ User.getName() }</div>
      </div>
      <ul className='menu-list sides-padding'>
        { ul }
      </ul>
    </div>
  );
})