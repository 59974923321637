import '../../assets/css/chat.css';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Actions from '../data/Actions';
import Chat from '../data/Chat';
import State from '../data/State';
import Utils from '../data/Utils';

const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];

export default observer(() => {
  State.getChatUpdate();
  Actions.markChatMessages();
  const [message, setMessage] = useState('');

  const users = Chat.getChatUsers().map((user, index) => {
    const classList = State.getChat() === user.id ? 'name user-active' : 'name';
    const mark = user.mark > 0 ? <div className='notif'>{ user.mark }</div> : null;
    return (
      <div key={ index } className='chat-user-block sides-padding' onClick={() => {
        Actions.getChat(user.id);
      }}>
        <div className='avatar'>{ mark }</div>
        <div className={ classList }>{ user.name }</div>
      </div>
    );
  });

  let day = '';
  const chat = Chat.getChat().map((message, key) => {
    const classList = message.userId === Utils.getAdminID() ? 'chat-message sides-padding chat-right' : 'chat-message sides-padding chat-left';
    const notifClass = message.userId !== Utils.getAdminID() ? 'chat-time' : message.userId === Utils.getAdminID() && message.mark === 0 ? 'chat-time chat-unread' : 'chat-time chat-read';
    
    const unixtime = Date.parse(message.time);
    const date = new Date(unixtime);
    const hours = date.getHours().toString().length === 1 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes();
    const current = date.getDate() + ' ' + months[date.getMonth()];
    const text = current !== day ? <div className='chat-date'>{ current }</div>: null;
    if (text !== null) day = current;

    return (
      <div key={ key }>
        { text }
        <div className={ classList }>
          <div className='chat-bubble'>
            { message.message }
            <div className={ notifClass }>{ hours + ':' + minutes }</div>
          </div>
        </div>
      </div>
    );
  });

  setTimeout(() => {
    const scroll = document.querySelector('.chat-scroll');
    scroll.scrollTo(0, scroll.scrollHeight);
  }, 0);

  const send = (): void => {
    if (message === '') return;
    State.sendChat(message);
    setMessage('');
  }
  const user = Chat.getChatUsers().find(user => user.id === State.getChat())
  const header = user ? user.name + ' | ' + user.phone + ' | id: ' + user.id : null;
  
  return (
    <div className='chat'>
      <div className='chat-users'>
        <div className='chat-header sides-padding'>Пользователи</div>
        <div style={{overflowY: 'auto'}}>{ users }</div>
      </div>
      <div className='chat-content'>
        <div className='chat-messages-header sides-padding'>{ State.getChat() !== null && header }</div>
        <div className='chat-scroll'>
          { chat }
        </div>
        <div className='chat-messages-footer'>
          <input
            type='text'
            className='chat-input'
            placeholder='Сообщение...'
            onChange={(e) => setMessage(e.target.value)}
            value={ message }
            onKeyPress={(e) => {
              if (e.key !== 'Enter') return;
              send();
            }}
          />
          <div className='chat-button' onClick={() => send()}></div>
        </div>
      </div>
    </div>
  );
});