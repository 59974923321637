import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import Auth from './components/Auth';
import Content from './components/Content';
import Menu from './components/Menu';
import Actions from './data/Actions';
import User from './data/User';

export default observer((): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      Actions.titleNotifications();
      await Actions.checkCookie();
      setLoaded(true);
    })();
  }, []);
  if (!loaded) return <></> 

  if (!User.getHash()) {
    return <Auth />
  } else {
    return (
      <div className='admin'>
        <Menu />
        <Content />
      </div>
    )
  }
});