enum screens {
  MAIN,
  NOTIF,
  REQUESTS,
  CHAT,
  FINANCE,
  SERVICES,
  RATES,
  USERS,
  ACCOUNTS
}
enum pushTypes {
  ALL = 'all',
  ID = 'id',
  PHONE = 'phone',
  SUM = 'sum',
  DATE = 'date',
  PENALTY = 'penalty'
}
enum requestStatus {
  SUCCESS = 1,
  PROGRES = 2,
  CLOSED = 3
}

export {
  screens,
  pushTypes,
  requestStatus
}