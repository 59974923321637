import '../../assets/css/auth.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import State from '../data/State';
import Actions from '../data/Actions';

export default observer(() => {
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const error = State.getAuthError() ? <div className='auth-error'>Неправильный логин или пароль</div> : null;

  return (
    <div className='auth'>
      <div className='auth-form'>
        <div className='auth-header'>Авторизация</div>
        { error }
        <div className='auth-input-block'>
          <div>логин:</div>
          <input type='text' name='login' required onChange={ (e) => setLogin(e.target.value) } value={ login } />
        </div>
        <div className='auth-input-block'>
          <div>пароль:</div>
          <input type='password' name='pass' required onChange={ (e) => setPass(e.target.value) } value={ pass } />
        </div>
        <button className='auth-button' onClick={() => {
          Actions.checkAuth(login, pass);
          setLogin('');
          setPass('');
        }}>Войти</button>
      </div>
    </div>
  );
});