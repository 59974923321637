import '../../assets/css/requests.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Actions from '../data/Actions';
import Requests from '../data/Requests';
import State from '../data/State';
import { requestStatus } from '../types/enums';

const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];

const getBgColor = (status: requestStatus): string => {
  return status === requestStatus.SUCCESS ? '#e5fee1' :
    status === requestStatus.PROGRES ? '#fffede' :
    status === requestStatus.CLOSED ? '#e3eeff' :
    '#E4E4E4';
}
const getTextColor = (status: requestStatus): string => {
  return status === requestStatus.SUCCESS ? '#228D22' :
    status === requestStatus.PROGRES ? '#DBAA20' :
    status === requestStatus.CLOSED ? '#20B6DB' :
    '#000000';
}
const getStatusName = (status: requestStatus): string => {
  return status === requestStatus.SUCCESS ? 'Операция успешна' :
    status === requestStatus.PROGRES ? 'В работе' :
    status === requestStatus.CLOSED ? 'Закрыто' :
    'Без статуса';
}
const parseDBTimeToString = (time: string): string => {
  const date = new Date(time);
  const day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
  const month = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours().toString().length === 1 ? '0' + date.getHours() : date.getHours();
  const minutes = date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes();
  return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
}

export default observer(() => {
  const [modal, setModal] = useState(false);
  State.getRequestsUpdate();
  const users = Requests.getUsers().map((user, index) => {
    const classList = Requests.getUserID() === user.id ? 'name user-active' : 'name';
    const mark = user.mark > 0 ? <div className='notif'>{ user.mark }</div> : null;
    return (
      <div key={ index } className='requests-user-block sides-padding' onClick={() => {
        setModal(false);
        Requests.setID(null);
        Requests.setComment('');
        Actions.getRequest(user.id);
      }}>
        <div className='avatar'>{ mark }</div>
        <div className={ classList }>{ user.name }</div>
      </div>
    );
  });

  const requests = Requests.getUserResquests().map((request, key) => {
    const classList = 'requests-message sides-padding requests-right';
    const date = new Date(request.time * 1000);
    const hours = date.getHours().toString().length === 1 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes();
    const current = date.getDate() + ' ' + months[date.getMonth()];
    const text = <div className='requests-date'>{ current }</div>

    const lastStatus = request.statuses[request.statuses.length - 1];
    const status = getStatusName(lastStatus?.status);
    const color = getTextColor(lastStatus?.status);
    const data = Array.isArray(request.data) ? request.data.map((li, key) => <div key={ key } className='requests-list'>{ li }</div>) : null;

    return (
      <div key={ key } onClick={() => {
        Requests.setID(request.id);
        setModal(true);
      }}>
        { text }
        <div className={ classList }>
          <div className='requests-bubble'>
            { request.product }
            { Array.isArray(request.data) && <div className='requests-list-block'>{ data }</div> }
            <div className='requests-status' style={{ color: color }}>{ status }</div>
            <div className='requests-time'>{ hours + ':' + minutes }</div>
          </div>
        </div>
      </div>
    );
  });

  const request = Requests.getUserResquests().find(data => data.id === Requests.getID());
  const user = Requests.getUsers().find(user => user.id === Requests.getUserID());
  const header = user ? user.name + ' | ' + user.phone + ' | id: ' + user.id : null;

  return (
    <div className='requests'>
      <div className='requests-users'>
        <div className='requests-header sides-padding'>Пользователи</div>
        <div style={{overflowY: 'auto'}}>{ users }</div>
      </div>
      <div className='requests-content'>
        <div className='requests-messages-header sides-padding'>{ Requests.getUserID() !== null && header }</div>
        <div className='requests-scroll'>
          { requests }
        </div>
        { modal && <div className='requests-statuses-modal-block'>
          <div className='requests-statuses-modal'>
            <div className='requests-messages-header requests-statuses-header-padding'>
              <div className='requests-statuses-close' onClick={() => {
                Requests.setID(null);
                Requests.setComment('');
                setModal(false);
              }}></div>
              <div>
                <div>{ header }</div>
                <div className='request-name'>{ request?.product }</div>
              </div>
            </div>
            <div id='requests-statuses-content' className='requests-statuses-content'>
              { request.statuses.map((data, key) => {
                return (
                  <div key={key} className='requests-status-bubble' style={{ background: getBgColor(data.status) }}>
                    <div className='request-status-info'><span>Статус:</span> <b>{ getStatusName(data.status) }</b></div>
                    <div className='request-status-info'>Комментарий:<br /><b>{ data.comment }</b></div>
                    <div className='request-status-info'>Время: <b>{ parseDBTimeToString(data.time) }</b></div>
                  </div>
                );
              }) }
            </div>
            <div className='requests-statuses-footer'>
              <div className='request-status-type-block'>
                <div className='request-status-new'>Новый статус:</div>
                <select className='request-status-select' defaultValue={Requests.getStatus()} onChange={e => {
                  Requests.setStatus(Number(e.target.value));
                }}>
                  {[requestStatus.SUCCESS, requestStatus.PROGRES, requestStatus.CLOSED].map((data, key) => {
                    return <option key={ key } value={ data }>{getStatusName(data)}</option>
                  })}
                </select>
              </div>
              <textarea
                id='request-status-textarea'
                placeholder='Комментарий...'
                onChange={(e) => Requests.setComment(e.target.value)}
                className='request-status-input'
                onKeyPress={(e) => {
                  if (e.key !== 'Enter') return;
                  Actions.addRequestStatus();
                }}
              />
              <div className='request-status-button' onClick={() => Actions.addRequestStatus()}></div>
            </div>
          </div>
        </div> }
      </div>
    </div>
  );
});