import { makeAutoObservable } from 'mobx';
import Utils from './Utils';

class User {
  constructor() {
    makeAutoObservable(this);
  }

  private _hash: string = null;
  private _name: string;
  private _role: number;
  private _id: number;

  public setHash(hash: string): void {
    this._hash = hash;
  }

  public getHash(): string {
    return this._hash;
  }

  public getName(): string {
    return this._name;
  }

  public setUserData(user: Iuser): void {
    this._name = user.name;
    this._role = user.role;
    this._id = user.id;
    this._hash = user.hash;
    const expires = Date.now() + 60 * 60 * 24 * 30 * 1000;
    Utils.setCookie('hash', this._hash, { expires: new Date(expires) });
  }
}

export default new User();