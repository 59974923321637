import '../../assets/css/main.css';
import { observer } from 'mobx-react-lite';
import { screens } from '../types/enums';
import State from '../data/State';
import Utils from '../data/Utils';
import Chat from '../data/Chat';

export default observer(() => {
  const menu = Utils.getMenu().map(data => {
    if (data.key === screens.MAIN) return null;
    const info = data.key === screens.CHAT ? Chat.getUnmarkMessages() + data.info : data.info;

    return (
      <div key={ data.key } className='main-card' onClick={() => State.setScreen(data.key)}>
        <div className='main-card-header'>{ data.text }</div>
        <div className='main-card-bottom-action'>{ data.action }</div>
        <div className='main-card-bottom-info'>{ info }</div>
      </div>
    );
  });

  return (
    <div className='main'>
      <div className='main-header sides-padding'>Главная</div>
      <div className='main-content'>
        { menu }
      </div>
    </div>
  );
});