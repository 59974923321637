import Utils from './Utils';

class Chat {

  private _users: IchatUser[] = [];
  private _messages: Imessage[] = [];

  public setChatUsers(users: IchatUser[]): void {
    this._users = users;
  }

  public addUserMessage(id: string): void {
    const user = this._users.find(data => data.id === id);
    user.mark++;
  }
  
  public addUser(message: Imessage): void {
    if (message.userId === Utils.getAdminID()) return;
    this._users.push({
      id: message.userId,
      name: message.name,
      mark: 1,
      phone: message.phone
    });
  }

  public clearMarks(id: string): void {
    const user = this._users.find(data => data.id === id);
    user.mark = 0;
  }

  public getChatUsers(): IchatUser[] {
    return this._users;
  }

  public setChat(messages: Imessage[]): void {
    this._messages = messages;
  }

  public getChat(): Imessage[] {
    return this._messages;
  }

  public addMessage(data: Imessage): void {
    this._messages.push(data);
  }

  public getUnmarkMessages(): number {
    return this._users.reduce((sum, current) => sum + current.mark, 0);
  }

  public getUnread(): number {
    let num = 0;
    this._users.map(user => num += user.mark);
    return num;
  }

  public clearMarkMessages() {
    for (const chat of this._messages) {
      chat.mark = 1;
    }
  }
}

export default new Chat();